<script setup lang="ts">
const { locale } = useI18n()
</script>

<template>
  <div class="flex min-h-screen w-full flex-col overflow-hidden">
    <LayoutHeader id="header" schema="color" :key="locale" />

    <slot />

    <LayoutFooter :key="locale" />
  </div>
</template>
